import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { debounce } from "lodash";

const SearchableSelect = ({
  fetchOptions,
  placeholder,
  onChange,
  defaultOptions,
}) => {
  const [options, setOptions] = useState(defaultOptions); // Khởi tạo với giá trị mặc định
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // Hàm gọi API khi nhập từ khóa
  const debouncedFetch = debounce(async (inputValue) => {
    setLoading(true);
    try {
      const fetchedOptions = await fetchOptions(inputValue);
      setOptions(fetchedOptions); // Gộp mảng mặc định và kết quả tìm kiếm
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleInputChange = (inputValue) => {
    if (inputValue) {
      debouncedFetch(inputValue); // Gọi API khi người dùng nhập từ khóa
    } else {
      setOptions(defaultOptions); // Reset về giá trị mặc định khi không có từ khóa
    }
  };

  const handleChange = (selected) => {
    setSelectedOption(selected);
    if (onChange) {
      onChange(selected); // Gọi callback onChange khi giá trị thay đổi
    }
  };

  const customMenu = (props) => (
    <components.Menu {...props}>
      {loading ? (
        <div className="px-3 py-2 text-gray-500">Đang tải dữ liệu...</div>
      ) : (
        props.children
      )}
    </components.Menu>
  );

  useEffect(() => {
    if (defaultOptions) setOptions(defaultOptions); // Cập nhật options khi defaultOptions thay đổi
  }, [defaultOptions]);

  return (
    <Select
      components={{ Menu: customMenu }}
      options={options}
      value={selectedOption}
      placeholder={placeholder}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isClearable
      isSearchable
    />
  );
};

export default SearchableSelect;
